import categories from '../../api/categories'

// initial state
const state = {
  all: []
}

// getters
const getters = {
  all: (state) => {
    return state.all;
  },
  slug: (state) => (slug) => {
    return state.all.find( (category) => category.slug == slug);
  },
  id: (state) => (id) => {
    return state.all.find( (category) => category.cat_ID == id);
  },
}

// actions
const actions = {
  getAll({ commit }) {
    return categories.get( categories => {
      commit('setCategories', categories);
    })
  }
}

// mutations
const mutations = {
  setCategories(state, categories) {
    state.all = categories
  },

  decrementProductInventory (state, { id }) {
    const product = state.all.find(product => product.id === id)
    product.inventory--
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
