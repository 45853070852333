import posts from '../../api/posts'

// initial state
const state = {
  all: []
}

// getters
const getters = {
  all: (state) => {
    return state.all;
  },
  slug: (state) => (slug) => {
    return state.all.find( (category) => category.slug == slug);
  },
  id: (state) => (id) => {
    return state.all.find( (category) => category.cat_ID == id);
  },
}

// actions
const actions = {
  getAll({ commit }) {
    posts.get( publications => {
      commit('setPublications', publications);
    })
  }
}

// mutations
const mutations = {
  setPublications(state, publications) {
    console.log('settings publications???');
    state.all = publications
  },

  decrementProductInventory (state, { id }) {
    const product = state.all.find(product => product.id === id)
    product.inventory--
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
