<template>
  <!-- <router-link :to="{name: 'publication', params: {'slug': publication.slug}}" :style="style"> -->
  <router-link class="publication" :to="{name: 'publication', params: {'slug': publication.slug}}">
    <span v-html="publication.title.rendered"></span>
    <span v-html="publication.subtitle" v-if="publication.subtitle"></span>
  </router-link>
  <!-- </router-link> -->
</template>

<script>

export default {
  name: 'Publication',
  props: {
    publication: {required: true},
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {
  },
  destroyed() {
  },
}
</script>
