const messages = {
    en: {
        architecture_photography: "Architecture Photography <strong>SELECTED WORK</strong>",
        all: "All",
        more: "More",
        work: "Work",
        projects: "Projects",
        message: {
            hello: 'hello world'
        }
    },
    pt: {
        architecture_photography: "Architecture Photography <strong>SELECTED WORK</strong>",
        all: "Todos",
        more: "Mais",
        work: "trabalho",
        projects: "Projectos",
        message: {
            hello: 'こんにちは、世界'
        }
    }
}

export default messages