<template>
  <section id="project" @contextmenu.prevent v-if="publication" class="project">
    <div class="info project_info" :class="{open: open}">
      <blat-v-scroll>
        <h1 v-text="publication.title.rendered"></h1> 
        <div v-html="publication.content.rendered">
        </div>
      </blat-v-scroll>

      <BlatProjects route_name="publication" :subtitle="false" :number="3" :pool="relatedProjects" class="project_otherProjects">
        <h3>Other Publications</h3>
      </BlatProjects>
    </div>

    <BlatGallery :project="publication" @toggleinfo="open = !open" class="project_gallery">
    </BlatGallery>

  </section>
</template>

<script>
import BlatProjects from '../Projects.vue'
import BlatGallery from '../Gallery.vue'
import BlatVScroll from '../VScroll.vue'

export default {
  name: 'Publication',
  components: {
    BlatProjects,
    BlatGallery,
    BlatVScroll,
  },
  props: {
    slug: String,
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    publicationsCategory() {
      return this.$store.getters['posts/categorySlug']('publications');
    },
    publications() {
      if( this.publicationsCategory )
        return this.$store.getters['posts/withCategory'](this.publicationsCategory);
      return [];
    },
    publication() {
      return this.$store.getters['posts/slug'](this.slug) || null;
    },
    allCategories() {
      return this.$store.getters['categories/all'] || [];
    },
    // categories() {
    //   if( this.project && this.allCategories.length ) {
    //     let categories = this.allCategories.filter( ({cat_ID}) => {
    //       return this.project.project_category.includes(cat_ID);
    //     });
    //     return categories.map( ({cat_ID}) => cat_ID );
    //   }
    //   return [];
    // },
    relatedProjects() {
      let publications = this.publications.filter( ({id}) => {
        return id != this.publication.id;
      });
      return publications.sort( () => Math.random() - .5 );
    },
  },
  methods: {
  },
  watch: {
    publicationsCategory: {
      handler() {
        if( this.publicationsCategory && this.publications.length == 0 ) {
          this.$store.dispatch('posts/get', {categories: [this.publicationsCategory.id]});
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('posts/getCategory', 'publications');
  },
}
</script>