<template>
  <section id="projects">
    <BlatProjects number="100" :category_slug="slug" :ignore_tag_slug="ignore_tag" :tag_slug="tag">
    </BlatProjects>
  </section>
</template>

<script>
import BlatProjects from '../Projects.vue'

export default {
  name: 'Projects',
  components: {
    BlatProjects,
  },
  props: {
    slug: String,
    tag: String,
    ignore_tag: String,
  },
  data() {
    return {
      open: false,
    }
  },
}
</script>