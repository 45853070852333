<template>
  <section id="home" v-if="loaded">
    <BlatBanner v-if="videos.length > 2" :videos="videos" :thumbnails="thumbnails" @go="$scroll($refs.content)"></BlatBanner>

    <div class="content" v-html="page.content.rendered" v-if="page" ref="content"></div>

    <BlatProjects tag_slug="selected" :priority="true">
      <p v-html="page.selected_work" v-if="page"><strong>SELECTED WORK</strong></p>
    </BlatProjects>
    <p>{{ $t("more") }} <router-link :to="{name: 'projects'}" v-text="$t('work')">work</router-link></p>
  </section>
</template>

<script>
import BlatProjects from '../Projects.vue'
import BlatBanner from '../Banner.vue'

export default {
  name: 'Home',
  metaInfo() {
    return {
      titleTemplate: null,
      title: this.seo_title,
      meta: [
        {
        vmid: 'og:title',
        property: 'og:title',
        content: this.seo_title,
        },
        {
        vmid: 'og:description',
        property: 'og:description',
        content: this.description,
        },
        {
        vmid: 'description',
        property: 'description',
        content: this.description,
        },
        {
        vmid: 'og:image',
        property: 'og:image',
        content: this.featured_image,
        },
      ]
    }
  },
  components: {
    BlatProjects,
    BlatBanner,
  },
  props: {
  },
  data() {
    return {
      open: false,
      loaded: false,
    }
  },
  watch: {
    page() {
      this.loaded = true;
    },
  },
  computed: {
    page() {
      return this.$store.getters['pages/slug']('home');
    },
    seo_title() {
      if( this.page ) {
        return this.page.seo.title;
      }
      return undefined;
    },
    description() {
      if( this.page ) {
        return this.page.seo.description;
      }
      return undefined;
    },
    featured_image() {
      return this.page ? this.page.featured_image.original : undefined;
    },
    randomIndexes() {
      if( this.page ) {
        let indexes = [...Array(this.page.thumbnails.length).keys()];
        let nr = indexes.length;
        let random = [];
        for (let i = 0; i < nr; i++) {
          let newIndex = Math.floor(Math.random() * (indexes.length));
          random.push(indexes[newIndex]);
          indexes.splice(newIndex,1);
        }
        return random;
      }
      return [];
    },
    thumbnails() {
      if( this.randomIndexes.length ) {
        let videos = [];
        for (let i = 0; i < this.randomIndexes.length; i++) {
          videos.push(this.page.thumbnails[this.randomIndexes[i]]);
        }
        return videos;
      }
      return [];
    },
    videos() {
      if( this.randomIndexes.length ) {
        let videos = [];
        let pageVideos = this.page.videos;
        for (let i = 0; i < this.randomIndexes.length; i++) {
          videos.push(pageVideos[this.randomIndexes[i]]);
        }
        return videos;
      }
      return [];
    },
  },
  mounted() {
    if( this.page ) {
      this.loaded = true;
    }
  },
}
</script>

<style scoped>
#header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
}
</style>
