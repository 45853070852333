<template>
  <section id="page" class="publications" v-if="loaded">
    <slot></slot>

    <ul>
      <li v-for="publication in ordered"
        :key="publication.slug"
        :style="style(publication)">
        <BlatPublication :publication="publication"></BlatPublication>
      </li>
    </ul>

    <div class="content" v-html="page.content.rendered"></div>
  </section>
</template>

<script>
import BlatPublication from '../Publication.vue'

export default {
  name: 'Publications',
  components: {
    BlatPublication,
  },
  props: {
    pageSlug: {default: 'published-work'},
  },
  data() {
    return {
      open: false,
      loaded: false,
      ordered: [],
    }
  },
  methods: {
    getPublications() {
      console.log('get publications');
      console.log(this.publicationsCategory);
      if( this.publicationsCategory && this.publications.length == 0 ) {
        console.log('getting publications');
        this.$store.dispatch('posts/get', {categories: [this.publicationsCategory.id]});
      }
    },
    style(publication) {
      return {
        'background-image': `url(${publication.featured_image.medium})`,
      };
    },
  },
  watch: {
    page: {
      handler() {
        this.loaded = this.page != null;
      },
      immediate: true,
    },
    publications: {
      handler() {
        console.log('ordering');
        this.ordered = this.publications.sort((a,b) => b.year - a.year);
        // this.ordered = [...this.publications];
      },
      immediate: true,
    },
    publicationsCategory: {
      handler: 'getPublications',
      immediate: true,
    },
  },
  computed: {
    page() {
      return this.$store.getters['pages/slug'](this.pageSlug);
    },
    publicationsCategory() {
      return this.$store.getters['posts/categorySlug']('publications');
    },
    publications() {
      if( this.publicationsCategory )
        return this.$store.getters['posts/withCategory'](this.publicationsCategory);
      return [];
    },
  },
  mounted() {
    this.$store.dispatch('posts/getCategory', 'publications');
  },
}
</script>