import posts from '../../api/posts'

// initial state
const state = {
  all: [],
  categories: [],
}

// getters
const getters = {
  all: (state) => {
    return state.all;
  },
  categories: (state) => {
    return state.categories;
  },
  categorySlug: (state) => (catSlug) => {
    return state.categories.find(({slug}) => slug == catSlug);
  },
  slug: (state) => (slug) => {
    return state.all.find( (category) => category.slug == slug);
  },
  id: (state) => (id) => {
    return state.all.find( (category) => category.cat_ID == id);
  },
  withCategory: (state) => ({id}) => {
    return state.all.filter( ({categories}) => categories.includes(id) );
  }
}

// actions
const actions = {
  get({ commit, rootGetters, dispatch }, params) {
    let lang = rootGetters['languages/current'];
    if( lang ) {
      posts.get( {...params, lang: lang.slug}, articles => {
        commit('addPosts', articles);
      })
    } else {
      setTimeout( () => {
        dispatch('get', params);
      },100)
    }
  },
  getAll({ commit }) {
    posts.get( categories => {
      commit('setCategories', categories);
    })
  },
  getCategory({ commit, rootGetters, dispatch }, slug) {
    let lang = rootGetters['languages/current'];
    if( lang ) {
      posts.getCategory( slug, lang, category => {
        commit('addCategory', category);
      })
    } else {
      setTimeout( () => {
        dispatch('getCategory', slug);
      },100)
    }
  },
}

// mutations
const mutations = {
  addCategory( state, category ) {
    let index = state.categories.indexOf( ({id}) => id == category.id);
    let categories = state.categories;
    if( index > -1 ) {
      categories = categories.splice( index, 1 );
    } else {
      categories.push( category );
    }
    state.categories = categories;
  },
  addPosts(state, posts) {
    let all = [...state.all];
    console.log(`Had: ${all.length}`);
    console.log(`Adding: ${posts.length}`);
    posts.forEach( (post) => {
      let index = all.findIndex( ({id}) => id == post.id);
      if( index > -1 ) {
        console.log(`Found: ${post.id}`);
        console.log(`Removing from: ${all.length}`);
        all.splice( index, 1 );
        console.log(`Removing to: ${all.length}`);
      }
      all.push( post );
    })
    console.log(`Finished: ${all.length}`);
    state.all = all;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
