import Vue from 'vue'
import Vuex from 'vuex'

import categories from './modules/categories'
import languages from './modules/languages'
import pages from './modules/pages'
import posts from './modules/posts'
import projects from './modules/projects'
import publications from './modules/publications'
import tags from './modules/tags'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    categories,
    languages,
    pages,
    posts,
    projects,
    publications,
    tags,
  },
  state: {
    count: 0
  },
  mutations: {
    increment (state) {
      state.count++
    }
  }
})

export default store