import api from './index.js'

export default {
    get(params = {}, cb) {
        params = {page: 1, per_page: 20, ...params};
        api.get('blat/v1/posts', params)
        .then( (res) => {
            cb(res.data);
            if( res.data.length == params.per_page ) {
                this.get({...params, page: params.page + 1}, cb);
            }
        });
    },
    getCategory(slug, lang, cb) {
        api.get('wp/v2/categories', {slug: slug})
        .then( ({data}) => {
            cb(data[0]);
        });
    },
    getCategories(params, cb) {
        api.get('wp/v2/categories', {params: params})
        .then( ({data}) => {
            cb(data);
        });
    },
}