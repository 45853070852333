<template>
  <div class="cookie" v-if="open">
    <p>
      This website uses cookies to offer and improve your user experience.<br>
      To learn more read our <a href="" @click.prevent="$emit('privacy')">Privacy Policy</a> which also includes our Policy on the use of cookies
    </p>
    <button @click.prevent="accept">I Accept</button>
    <svg class="close" @click.prevent="open = false">
      <use href="../assets/menu.svg#cancel"/>
    </svg>
  </div>
</template>

<script>

import { bootstrap } from 'vue-gtag'

export default {
  name: 'Cookie',
  components: {
  },
  props: {
  },
  data() {
    return {
      open: true,
    }
  },
  methods: {
    accept() {
      this.$cookies.set('cookieConsent', 1, {expires: 30});
      this.$emit('consent', true);
      this.open = false;
      bootstrap();
    },
    saveLanguage() {
      if( this.language ) {
        this.$cookies.set('language', this.language.slug, {expires: 30});
      }
    }
  },
  computed: {
    language() {
      return this.$store.getters['languages/current'];
    }
  },
  watch: {
    language: {
      handler() {
        this.saveLanguage();
      },
      immediate: true,
    }
  },
  created() {
  },
  mounted() {
    let gotConsent = this.$cookies.get('cookieConsent') == '1';
    this.open = !gotConsent;
    if( gotConsent ) {
      this.accept();
    }
  },
  destroyed() {
  },
}
</script>