<template>
  <section class="projects" @contextmenu.prevent>
    <slot></slot>
    <transition name="fade" mode="out-in">
      <ul :key="`${category_slug}${$route.name}`">
        <li v-for="project in projects"
          :key="project.slug"
          :style="style(project)">
          <svg v-if="project.video">
            <use :href="`${icons}#play-simple`"/>
          </svg>
          <router-link
            :to="{name: routeName(project), params: {lang: $root.lang,'slug': project.default_lang_slug, category: projectCategory(project)}}"
            v-slot="{ href, route, navigate }"
            @click="projectClick">
            <a :href="href" @click.prevent="projectClick(project, navigate, route)"
              :class="{hover: hoverProject == project}">
              <span v-html="project.title.rendered"></span>
              <span v-html="project.subtitle" v-if="subtitle"></span>
            </a>
          </router-link>
        </li> 
      </ul>
    </transition>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import icons from '../assets/menu.svg'

export default {
  name: 'Projects',
  props: {
    route_name: {defualt: null},
    number: {default: 9},
    category_slug: String,
    tag_slug: String,
    ignore_tag_slug: String,
    subtitle: {default: true},
    pool: {default: null},
    priority: {default: false},
  },
  data() {
    return {
      canHover: true,
      hoverProject: null,
      icons: icons,
      media: [
      ],
    }
  },
  methods: {
    style(project) {
      let img = project.thumbnail || '/images/the_wall_house.jpg';
      return {
        'background-image': `url(${img})`,
      }
    },
    routeName(project) {
      if( this.route_name ) return this.route_name;
      return project.video ? 'video' : 'Project';
    },
    projectCategory(project) {
      if( project.project_category ) {
        let category = this.$store.getters['categories/id'](project.project_category[0]);
        return category.slug;
      }
      return null;
    },
    projectClick(project, navigate, route) {
      if( this.canHover || this.hoverProject == project ) {
        navigate(route);
      } else {
        this.hoverProject = project;
      }
    }
  },
  computed: {
    ...mapState('projects', {
      allProjects: 'all'
    }),
    category() {
      if( this.category_slug ) {
        return this.$store.getters['categories/slug'](this.category_slug);
      }
      return null;
    },
    tag() {
      if( this.tag_slug ) {
        return this.$store.getters['tags/slug'](this.tag_slug);
      }
      return null;
    },
    ignoreTag() {
      if( this.ignore_tag_slug ) {
        return this.$store.getters['tags/slug'](this.ignore_tag_slug);
      }
      return null;
    },
    projects() {
      let projects = [];
      if( this.pool && this.pool.length >= this.number ) {
        projects = this.pool.slice(0, this.number );
      } else if( this.pool ) {
        projects = [...this.pool, ...this.filtered.slice(0, this.number - this.pool.length)];
      } else {
        projects = this.filtered.slice(0, this.number );
      }
      if( this.priority ) {
        projects = projects.sort( (a,b) => a.priority - b.priority );
      }
      return projects;
    },
    filtered() {
      if( this.category && this.tag ) {
        return this.$store.getters['projects/categogy_tag'](this.category, this.tag);
      } else if( this.category ) {
        return this.$store.getters['projects/category'](this.category, this.ignoreTag);
      } else if( this.tag ) {
        return this.$store.getters['projects/tag'](this.tag);
      } else if( this.ignoreTag ) {
        return this.$store.getters['projects/without_tag'](this.ignoreTag);
      } else if( this.allProjects ) {
        return this.allProjects;
      }
      return [];
    },
  },
  mounted() {
    let canHover = getComputedStyle(document.documentElement).getPropertyValue('--hover');
    if( canHover == 0 ) {
      this.canHover = false;
    }
  },
  destroyed() {
  },
}
</script>

<style scoped>
</style>
