import './sass/style.scss'
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Scroll from 'animated-scroll-to'
import Cookies from 'js-cookie'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import messages from './messages'
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
})

Vue.use(VueRouter)
Vue.config.productionTip = false

Vue.prototype.$scroll = Scroll
Vue.prototype.$cookies = Cookies

import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
import axios from './load_axios'
Vue.mixin({
  data() {
   return {
     apiBaseUrl: 'https://clientes.ricardooliveiraalves.com/wp-json/',
   } 
  },
  methods: {
    get(url, params) {
      return axios.get(`${this.apiBaseUrl}${url}`, {params: params});
    },
  }
})

import {createRouter} from './router.js'
import VueGtag from "vue-gtag";

import store from './store'
import metaInfo from './metaInfo'

export function createApp ({server = false}) {
  Vue.prototype.$server = server;
  if( !server ) {
    Vue.prototype.$player = require('@vimeo/player').default;
  }
  let router = createRouter({server});
  Vue.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS
    },
    bootstrap: false
  }, router);
  let app = new Vue({
    router,
    store,
    i18n,
    metaInfo,
    render: h => h(App),
    serverPrefetch() {
      if( this.$route.params.lang ) {
        this.$store.commit('languages/setLanguage', this.$route.params.lang);
      } else {
        this.$store.commit('languages/setLanguage');
      }
    },
    computed: {
      lang() {
        if( this.$store.state.languages.language != 'en' ) {
          return this.$store.state.languages.language;
        }
        return null;
      },
      locale() {
        return this.$store.state.languages.language;
      },
    },
    created() {
      if( this.$route.params.lang ) {
        this.$store.commit('languages/setLanguage', this.$route.params.lang);
      } else {
        this.$store.commit('languages/setLanguage');
      }
    },
    watch: {
      locale: {
        handler() {
          this.$i18n.locale = this.locale;
        }
      },
      lang: {
        immediate: false,
        handler() {
          if( ( this.lang != null && this.$route.params.lang != this.lang) ||
              ( this.lang == null && this.$route.params.lang != null ) ) {
            let route = {...this.$route};
            route.params = {...route.params, lang: this.lang};
            this.$router.push(route);
          }
        }
      }
    }
  })
  return { app, router };
}
