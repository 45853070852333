import tags from '../../api/tags'

// initial state
const state = {
  all: []
}

// getters
const getters = {
  slug: (state) => (slug) => {
    return state.all.find( (tag) => tag.slug == slug);
  },
}

// actions
const actions = {
  getAll({ commit }) {
    tags.get( tag => {
      commit('setTags', tag);
    })
  }
}

// mutations
const mutations = {
  setTags(state, tags) {
    state.all = tags
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
