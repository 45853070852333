import pages from '../../api/pages'

// initial state
const state = {
  all: []
}

// getters
const getters = {
  slug: (state) => (slug) => {
    return state.all.find( (page) => page.default_lang_slug === slug )
  },
  all: (state) => {
    return state.all;
  }
}

// actions
const actions = {
  getAll({ commit, rootGetters, dispatch }) {
    let lang = rootGetters['languages/current'];
    if( lang ) {
      pages.get({lang: lang.slug}, receivedPages => {
        commit('setPages', receivedPages );
      })
    } else {
      setTimeout( () => {
        dispatch('getAll');
      })
    }
  }
}

// mutations
const mutations = {
  setPages(state, projects) {
    state.all = projects
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
