<template>
  <section id="footer">
    <ul>
      <li v-for="network in networks"
        :key="network.name">
        <a :href="network.url" target="_blank">
          <span v-text="network.name"></span>
          <svg>
            <use :href="`${icons}#${network.icon}`"/>
          </svg>
        </a>
      </li> 
    </ul> 
    <p class="privacy"><router-link :to="{path: '/privacy-policy'}">Privacy Policy</router-link></p>
    <p><strong>© Ricardo Oliveira Alves</strong></p>
  </section>
</template>

<script>
import SocialIcons from '../assets/social.svg';

export default {
  name: 'Footer',
  props: {
  },
  data() {
    return {
      icons: SocialIcons,
      networks: [
        {name: 'Facebook', icon: 'facebook', url: 'https://www.facebook.com/ricardooliveiraalvesphotography/'},
        {name: 'Instagram', icon: 'instagram', url: 'https://www.instagram.com/ricardoliveiralves/'},
        {name: 'Pinterest', icon: 'pinterest', url: 'https://www.pinterest.pt/roaphotography/'},
        {name: 'Twitter', icon: 'twitter', url: 'https://twitter.com/roaphotography'},
        {name: 'Vimeo', icon: 'vimeo', url: 'https://vimeo.com/ricardooliveiraalves'},
      ]
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
