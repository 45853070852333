<template>
  <section class="gallery" v-if="imagesLoaded"
    :class="{hover: hover, mobile: mode == 'mobile', desktop: mode != 'mobile', full: mode == 'full'}">
    <template v-if="mode == 'mobile'">
      <div class="image"
        v-for="(current, index) in orderedImages"
        :key="current.id">
        <img :src="current.source_url" alt=""
          :class="{hide: !loaded.includes(current.id)}"
          @load="pushLoaded(current.id)"
          v-if="index < mobileToShow">
      </div>
      <div class="loading" ref="loading" v-if="mobileToShow < project.images.length">
        A carregar...
      </div>
    </template>
    
    <template v-else>
      <transition name="up">
        <div v-if="mode == 'full'" class="full" key="full">
          <div class="prev" @click.prevent="activate(active-1)">
            <svg>
              <use href="../assets/menu.svg#chevron"></use>
            </svg>
          </div>
          <div class="viewport">
            <transition :name="animation"
              v-for="(current, index) in orderedImages" :key="index">
              <div class="image" v-show="current == image && loaded.includes(current.id)">
                <img :src="current.source_url" alt=""
                  v-if="loaded.includes(current.id) || current == image"
                  @load="pushLoaded(image.id)"
                  :key="image.id">
              </div>
            </transition>
          </div>
          <div class="next" @click.prevent="activate(active+1)">
            <svg>
              <use href="../assets/menu.svg#chevron"></use>
            </svg>
          </div>
        </div> 
        <div class="grid" v-else key="grid">
          <div v-for="(image, index) in orderedImages" :key="image.id" class="preview"
            @click.prevent="activate(index)">
            <transition name="fade">
              <img :src="image.media_details.sizes.squarethumb.source_url" alt=""
                @load="pushLoadedPreview(image.id)"
                v-show="loadedPreview.includes(image.id)">
            </transition> 
          </div>
        </div>
      </transition>
    </template>

    <ul class="menu menu-icons project_menu" :style="menuStyle" ref="menu">
      <li class="menu_item menu_item-largeIcon" @click.prevent="goBack">
        <svg>
          <use href="../assets/menu.svg#back"></use>
        </svg>
      </li>
      <li class="menu_item menu_item-icon menu_item-text" @click.prevent="toggleInfo">
        <strong class="big">+</strong> info
      </li>
      <li v-if="mode != 'mobile'" class="menu_item menu_item-icon" @click.prevent="toggleGrid">
        <svg>
          <use href="../assets/menu.svg#grid"></use>
        </svg>
      </li>
    </ul>

  </section>
</template>

<script>
export default {
  components: {
  },
  name: 'Gallery',
  props: {
    project: {required: true},
  },
  data() {
    return {
      mode: 'mobile',
      mobileToShow: 5,
      loaded: [],
      loadedPreview: [],
      oldImage: null,
      active: 0,
      hover: false,
      images: [],
      imagesLoaded: false,
      mouseTimeout: null,
      loadingInterval: null,
      animation: 'up',
      onBottom: false,
    }
  },
  computed: {
    orderedImages() {
      let images = [];
      for (let i = 0; i < this.project.images.length; i++) {
        let imageId = this.project.images[i];
        let image = this.images.find(({id}) => id == imageId );
        if( image ) {
          images.push(image);
        }
      }
      return images;
    },
    info() {
      return this.$parent.open;
    },
    image() {
      return this.orderedImages[this.active];
    },
    columnsStyle() {
      return {
        "grid-template-columns": `repeat(${this.images.length + 4}, max-content)`,
      }
    },
    menuStyle() {
      let style = {};
      if( !this.onBottom  || this.info ) {
        style.bottom = 0;
      }
      return style;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    activate(index) {
      if( index < this.active && this.mode != 'grid' ) {
        this.animation = 'toleft';
      } else if( this.mode == 'grid' ) {
        this.animation = 'up';
        this.mode = 'full';
      } else {
        this.animation = 'toright';
      }
      if( index < 0 ) {
        index = this.project.images.length + index;
      }
      index = index % this.project.images.length;
      if( index != this.active ) {
        this.oldImage = this.image;
        this.active = index;
      }
    },
    arrowListener(event) {
      let nextKeys = ["ArrowRight", " ", "j", "l"];
      let prevKeys = ["ArrowLeft",  "k", "h"];
      if( nextKeys.includes(event.key) ) {
        this.activate(this.active + 1);
      } else if( prevKeys.includes(event.key) ) {
        this.activate(this.active - 1);
      }
    },
    cancelMenuClose() {
      clearTimeout(this.mouseTimeout);
      this.mouseTimeout = null;
    },
    mouseLeft() {
      if(this.mouseTimeout == null ) {
        this.mouseTimeout = setTimeout(() => {
          this.hover = false;
          this.mouseTimeout = null;
        }, 200);
      }
    },
    mousing(event) {
      let selfRect = this.$el.getBoundingClientRect();
      let bottom = selfRect.y + selfRect.height;
      let menuRect = this.$refs.menu.$el.getBoundingClientRect();
      if( bottom - menuRect.height < event.y ) {
        this.cancelMenuClose();
        this.hover = true;
      } else {
        this.mouseLeft();
      }
    },
    pushLoadedPreview(id) {
      if( !this.loadedPreview.includes(id) ) {
        setTimeout(() => this.loadedPreview.push(id), 150);
      }
    },
    pushLoaded(id) {
      if( !this.loaded.includes(id) ) {
        setTimeout(() => this.loaded.push(id),150);
      }
    },
    toggleInfo() {
      this.$emit('toggleinfo');
    },
    toggleGrid() {
      if( this.mode == 'full' ) {
        this.mode = 'grid';
      } else if( this.mode == 'grid' ) {
        this.mode = 'full';
      }
      this.animation = "up";
    },
    fetchImages() {
      let fetched = this.images.map( image => image.id );
      let toFetch = [];
      for (let i = 0; i < this.project.images.length; i++) {
        let image = this.project.images[i];
        if( !fetched.includes(image) ) {
          toFetch.push(image);
        }
      }
      if( toFetch.length ) {
        this.get('wp/v2/media', {include: toFetch})
        .then(({data}) => {
          this.images = [...this.images, ...data];
          this.fetchImages();
        });
      } else {
        this.imagesLoaded = true;
      }
    },
    scrollListener() {
      this.onBottom = false;
      if( this.$refs.loading ) {
        let box = this.$refs.loading.getBoundingClientRect();
        if( box.y < (window.innerHeight * 1.5) ) {
          this.startLoading();
        }
      }
      if( this.loadingInterval == null ) {
        let footerBox = document.getElementById('footer').getBoundingClientRect();
        this.onBottom =  footerBox.y < window.innerHeight;
      }
    },
    startLoading() {
      if( this.loadingInterval == null ) {
        let loading = this.$refs.loading;
        this.loadingInterval = setInterval(() => {
          let box = loading.getBoundingClientRect();
          this.mobileToShow++;
          if( box.y > (window.innerHeight * 2) ) {
            this.stopLoading();
          } 
        }, 200);
      }
    },
    stopLoading() {
      clearInterval(this.loadingInterval);
      this.loadingInterval = null;
    },
  },
  watch: {
    project() {
      this.imagesLoaded = false;
      this.active = 0;
      this.images = [];
      this.fetchImages();
    },
    mobileToShow() {
      if( this.mobileToShow >= this.project.images.length ) {
        // document.removeEventListener("scroll", this.scrollListener);
        this.stopLoading();
      }
    },
  },
  mounted() {
    this.fetchImages();
    let canHover = getComputedStyle(document.documentElement).getPropertyValue('--hover');
    this.mode = canHover == 0 ? 'mobile' : 'full';
    if( this.mode == 'mobile' ) {
      document.addEventListener( "scroll" , this.scrollListener);
    } else {
      document.addEventListener("keydown", this.arrowListener);
    }
  },
  destroyed() {
    document.removeEventListener("scroll", this.scrollListener);
    document.removeEventListener("keydown", this.arrowListener);
    this.stopLoading();
  },
}
</script>

<style scoped>
</style>