import projects from '../../api/projects'

// initial state
const state = {
  all: []
}

// getters
const getters = {
  all: (state) => {
    return state.all;
  },
  category: (state) => (cat, ignoreTag) => {
    let projects = [];
    for (let i = 0; i < state.all.length; i++) {
      const project = state.all[i];
      if( project.project_category.includes(cat.cat_ID) && !project.project_tag.includes(ignoreTag.cat_ID) ) {
        projects.push(project);
      }
    }
    return projects;
  },
  categogy_tag: (state) => (cat, tag) => {
    let projects = [];
    for (let i = 0; i < state.all.length; i++) {
      const project = state.all[i];
      if( project.project_category.includes(cat.cat_ID) && project.project_tag.includes(tag.cat_ID) ) {
        projects.push(project);
      }
    }
    return projects;
  },
  category_slug: (state) => (category, tag) => {
    let projects = [];
    for (let i = 0; i < state.all.length; i++) {
      const project = state.all[i];
      if( project.project_category.includes(category.cat_ID) && project.project_tag.includes(tag.cat_ID) ) {
        projects.push(project);
      }
    }
    return projects;
  },
  without_tag: (state) => (tag) => {
    let projects = [];
    for (let i = 0; i < state.all.length; i++) {
      const project = state.all[i];
      if( !project.project_tag.includes(tag.cat_ID) ) {
        projects.push(project);
      }
    }
    return projects;
  },
  tag: (state) => (tag) => {
    let projects = [];
    for (let i = 0; i < state.all.length; i++) {
      const project = state.all[i];
      if( project.project_tag.includes(tag.cat_ID) ) {
        projects.push(project);
      }
    }
    return projects;
  },
  slug: (state) => (slug) => {
    return state.all.find( (project) => project.default_lang_slug === slug );
  },
}

// actions
const actions = {
  getAll({commit, rootGetters, dispatch}) {
    let current = rootGetters['languages/current'];
    if( current ) {
      projects.get( receivedProjects => {
        commit('add', receivedProjects);
      }, {lang: current.slug})
    } else {
      setTimeout( () => {
        dispatch('getAll');
      },100)
    }
  }
}

// mutations
const mutations = {
  add(state, projects) {
    let all = [...state.all];
    projects.forEach( (project) => {
      let index = all.indexOf( ({id}) => id == project.id);
      if( index > -1 ) {
        all = all.splice( index, 1 );
      }
      all.push( project );
    })
    state.all = all;
  },
  setProjects(state, projects) {
    state.all = projects
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
