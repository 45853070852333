<template>
  <div class="blat-hscroll">
    <div class="blat-scrollcontent"
      :style="{...style, ...scrollStyle}"
      ref="content"
      @scroll="scrollListener">
      <slot></slot>
    </div>
    <div class="blat-scrolltrack" :class="{active: scrolling, scrolling: isScrolling}" ref="track">
      <div class="blat-scrollbar"
        @mousedown="initScroll"
        :style="{ top: scrollbarTop + 'px', height: scrollbarHeight + 'px' }">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scrollStyle: {default: () => new Object()},
  },
  components: {
  },
  data: function () {
    return {
      scrollHeight: 0,
      scrollTop: 0,
      scrollbarHeight: 0,
      scrollbarTop: 0,
      scrolltrackHeight: 0,
      position: 0,
      hiddenMargin: null,
      mouseStart: null,
      isScrolling: false,
      scrollTimeout: null,
      checkScrollPositionInterval: null,
    }
  },
  methods: {
    disableSelect($event) {
      $event.preventDefault();
    },
    initScroll($event) {
      this.mouseStart = { mouse: $event.y, barTop: this.scrollbarTop}
      document.addEventListener('mouseup', this.endScroll, false);
      document.addEventListener('mousemove', this.moveScroll, false);
      document.addEventListener('selectstart', this.disableSelect, false);
    },
    moveScroll($event) {
      let position = this.mouseStart.barTop + ($event.y - this.mouseStart.mouse);
      let maxTop = this.scrolltrackHeight - this.scrollbarHeight;
      let scrollbarTop = Math.max(0, Math.min(position, maxTop));
      let scrollTop = (scrollbarTop*this.scrollHeight)/this.scrolltrackHeight;
      this.$refs.content.scrollTop = scrollTop;
    },
    endScroll() {
      this.mouseStart = null;
      document.removeEventListener('mousemove', this.moveScroll, false)
      document.removeEventListener('mouseup', this.endScroll, false);
      document.removeEventListener('selectstart', this.disableSelect, false);
    },
    checkScrollPosition() {
      this.scrollHeight = this.$refs.content.scrollHeight;
      this.scrollTop = this.$refs.content.scrollTop;
      this.scrolltrackHeight = this.$refs.track.clientHeight;
      this.scrollbarHeight = Math.ceil((this.scrolltrackHeight*this.scrolltrackHeight)/this.scrollHeight);
      this.scrollbarTop = (this.scrollTop*this.scrolltrackHeight)/this.scrollHeight;
    },
    scrollListener(event) {
      clearTimeout(this.scrollTimeout);
      this.isScrolling = true;
      this.checkScrollPosition();
      this.scrollTimeout = setTimeout( () => {
        this.isScrolling = false;
      }, 1000);
      this.$emit('scroll', event);
    },
  },
  computed: {
    style() {
      if( this.hiddenMargin != null ) {
        let style = this.$refs.content.currentStyle || window.getComputedStyle(this.$refs.content);
        let padding = 50;
        return {
          'margin-right': `${this.hiddenMargin + parseInt(style.marginRight) - padding}px`,
          'padding-right': `${padding + parseInt(style.paddingRight)}px`,
        };
      }
      return {};
    },
    $scrolltrack() {
      return this.$el.querySelector('.blat-scrolltrack');
    },
    scrolling() {
      return this.mouseStart != null;
    }
  },
  mounted() {
    let style = this.$refs.content.currentStyle || window.getComputedStyle(this.$refs.content);
    let margin = parseInt(style.marginRight);
    this.hiddenMargin = margin - (this.$refs.content.offsetWidth - this.$refs.content.clientWidth);
    this.checkScrollPositionInterval = setInterval(() => {
      this.checkScrollPosition();
    }, 500);
  },
  beforeDestroy() {
    clearInterval(this.checkScrollPositionInterval);
  }
}
</script>

<style scoped>
.blat-hscroll {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
}
.blat-scrolltrack,
.blat-scrollbar {
  width: 4px;
}
.blat-scrolltrack {
  position: absolute;
  top: 0; 
  right: 0; 
  bottom: 0; 
  background: rgba(0, 0, 0, 0.05);
  z-index: 1000;
}
.blat-scrollbar {
  position: relative;
  background: rgba(0, 0, 0, 0.3);
}
.blat-scrollcontent {
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  height: 100%;
}
</style>
