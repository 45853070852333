<template>
  <div id="app">
    <BlatHeader/>
    <transition name="up" mode="out-in">
      <router-view :key="$route.path"></router-view>
    </transition>
    <BlatFooter/>
    <CookieConsent></CookieConsent>
  </div>
</template>

<script>
import BlatHeader from './components/Header.vue'
import BlatFooter from './components/Footer.vue'
import CookieConsent from './components/CookieConsent.vue'

export default {
  name: 'app',
  components: {
    BlatHeader,
    BlatFooter,
    CookieConsent,
  },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.$store.state.languages.language,
      },
    }
  },
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created() {
  },
  mounted() {
  },
}
</script>

<style>
#app {
  margin: auto;
  position: relative; 
  min-height: 100vh;
}
</style>
