const metaInfo = {
  title: 'Ricardo Oliveira Alves Photograpy & Film Studio',
  titleTemplate: '%s | Ricardo Oliveira Alves',
  htmlAttrs: {
    lang: 'en',
  },
  meta: [
    {
    vmid: 'description',
    property: 'description',
    content: "Architecture photography and video holds the value of being an immediate and unique way to bring architecture to people",
    },
    {
    vmid: 'og:description',
    property: 'og:description',
    content: "Architecture photography and video holds the value of being an immediate and unique way to bring architecture to people",
    },
    {
    vmid: 'og:title',
    property: 'og:title',
    content: "Ricardo Oliveira Alves Photograpy & Film Studio",
    },
  ]
}

export default metaInfo