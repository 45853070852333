// import axios from 'axios'
const axios = require('axios');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.request.use(function (config) {
  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
    // 'Accept': 'application/json',
  }
  if( config.url === undefined ) {
    throw "ERROR";
  }
  // if(config.url.endsWith('/')) {
  //   config.url = config.url.slice(0, -1);
  // }
  // if(!config.url.endsWith('.json')) {
  //   config.url += ".json"
  // }
  // config.params = {...config.params, format: 'json'};
  return config;
}, function (error) {
  return Promise.reject(error);
});
// let token = document.head.querySelector('meta[name="csrf-token"]');
// if (token) {
//     window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
// } else {
//     console.error('CSRF token not found');
export default axios