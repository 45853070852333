<template>
  <section class="video" @contextmenu.prevent :class="{paused: !playing, playing: playing}">
    <!-- <transition name="fade">
      <div class="thumbnail" v-if="!started">
        <img :src="project.thumbnail" alt="">
      </div>
    </transition> -->
    <div class="control" @click.prevent="togglePlay()">
      <svg class="pause">
        <use href="../assets/menu.svg#pause"></use>
      </svg>
    </div>
    <div class="player" ref="player" @click.prevent="togglePlay()"></div>
    <ul class="menu menu-icons project_menu">
      <li class="menu_item menu_item-largeIcon">
        <svg @click.prevent="goBack">
          <use href="../assets/menu.svg#back"></use>
        </svg>
      </li>
      <li class="menu_item menu_item-icon menu_item-text" @click.prevent="toggleInfo">
        <strong class="big">+</strong> info
      </li>
      <transition name="fade" mode="out-in">
        <li v-if="playing" class="menu_item menu_item-icon">
          <svg @click.prevent="player.pause()">
            <use href="../assets/menu.svg#pause"></use>
          </svg>
        </li>
        <li v-else class="menu_item menu_item-icon">
          <svg @click.prevent="player.play()">
            <use href="../assets/menu.svg#play"></use>
          </svg>
        </li>
      </transition>
      <transition name="fade" mode="out-in">
        <li v-if="muted" class="menu_item menu_item-icon">
          <svg @click.prevent="player.setVolume(1)">
            <use href="../assets/menu.svg#soundoff"></use>
          </svg>
        </li>
        <li v-else class="menu_item menu_item-icon">
          <svg @click.prevent="player.setVolume(0)">
            <use href="../assets/menu.svg#soundon"></use>
          </svg>
        </li>
      </transition>
      <li class="menu_item menu_item-icon">
        <svg @click.prevent="fullscreen">
          <use href="../assets/menu.svg#fullscreen"></use>
        </svg>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'Video',
  props: {
    project: {required: true},
    options: {default: () => {
        return {
          id: null,
          width: 1920,
          controls: false,
          autoplay: true,
          muted: false,
          quality: '1080p',
        }
      }
    },
  },
  data() {
    return {
      player: null,
      playing: false,
      started: false,
      muted: false,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fullscreen() {
      let elem = this.$refs.player;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },
    toggleInfo() {
      this.$emit('toggleinfo');
    },
    togglePlay() {
      if( this.playing ) {
        this.player.pause();
      } else {
        this.player.play();
      }
    },
  },
  computed: {
  },
  mounted() {
    if( !this.$server ) {
      this.options.id = this.project.video;
      this.player = new this.$player(this.$refs.player, this.options);
      this.player.on('play', () => {
        this.started = true
        this.playing = true
      });
      this.player.on('pause', () => this.playing = false);
      this.player.on('ended', () => this.started = false);
      this.player.on('volumechange', (event) => this.muted = event.volume == 0);
      // this.player.on('progress', (event) => console.log(event));
      this.player.on('timeupdate', (event) => this.started = event.seconds < (event.duration - 0.5));
    }
  },
  destroyed() {
    if( !this.$server ) {
      this.player.off('play');
      this.player.off('pause');
      this.player.off('ended');
      this.player.off('volumechange');
      this.player.off('progress');
      this.player.off('timeupdate');
    }
  },
}
</script>
