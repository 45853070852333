import api from './index.js'

export default {
    get(cb, params = {}) {
        params = {page: 1, per_page: 50, ...params};
        api.get('blat/v1/projects', params)
        .then( (res) => {
            cb(res.data);
            if( res.data.length == params.per_page ) {
                this.get(cb, {...params, page: params.page + 1});
            }
        });
    },
}