import api from './index.js'
/**
 * Mocking client-server processing
 */
export default {
    get(cb) {
        api.get('blat/v1/tags')
        .then( ({data}) => {
            cb(data);
        });
    },
}