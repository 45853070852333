import api from './index.js'
/**
 * Mocking client-server processing
 */
export default {
    get(params = {}, cb) {
        params = {page: 1, per_page: 20, ...params};
        api.get('blat/v1/pages', params)
        .then( ({data}) => {
            cb(data);
            if( data.length == params.per_page ) {
                this.get({...params, page: params.page + 1}, cb);
            }
        });
    },
}