import languages from '../../api/languages'
import Cookies from 'js-cookie'

// initial state
const state = {
  all: [],
  language: null,
  
}

// getters
const getters = {
  all: (state) => {
    return state.all;
  },
  current: (state) => {
    if( state.language != null && state.all.length > 0 ) {
      // console.log(state.all.find( ({slug}) => slug == state.language));
      return state.all.find( ({slug}) => slug == state.language);
    }
    return null;
  }
}

// actions
const actions = {
  getAll({ commit }) {
    return languages.get( receivedLanguages => {
      commit('add', receivedLanguages);
    })
  },
}

// mutations
const mutations = {
  setLanguage(state, lang = null) {
    console.log('setting language');
    if( lang == null ) {
      let language = Cookies.get('language');
      if( typeof window !== 'undefined' && window !== null ) {
        language = language || window.navigator.userLanguage || window.navigator.language;
      }
      language = language || "en";
      state.language = language.slice(0,2).toLowerCase();
    } else {
      state.language = lang;
    }
    console.log(state.language);
  },
  add(state, languages) {
    let all = [...state.all];
    languages.forEach( (language) => {
      let index = all.indexOf( ({id}) => id == language.id);
      if( index > -1 ) {
        all = all.splice( index, 1 );
      }
      all.push( language );
    })
    state.all = all;
  },
  setLanguages(state, languages) {
    state.all = languages
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
