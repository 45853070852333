<template>
  <section id="project" @contextmenu.prevent v-if="project" class="project">
    <div class="info project_info" :class="{open: open}">
      <blat-v-scroll>
        <h1 v-text="project.title.rendered"></h1> 
        <div v-html="project.content.rendered"></div>
      </blat-v-scroll>

      <BlatProjects :subtitle="false" :number="3" :pool="relatedProjects" class="project_otherProjects">
        <h3 v-text="$store.state.languages.language == 'en' ? 'Other Projects' : 'Outros Projetos'"></h3>
      </BlatProjects>
    </div>

    <BlatVideo :project="project" v-if="project.video" @toggleinfo="open = !open" class="project_video">
    </BlatVideo>
    <BlatGallery :project="project" v-else @toggleinfo="open = !open" class="project_gallery">
    </BlatGallery>

  </section>
</template>

<script>
import BlatProjects from '../Projects.vue'
import BlatGallery from '../Gallery.vue'
import BlatVScroll from '../VScroll.vue'
import BlatVideo from '../Video.vue'

export default {
  name: 'Project',
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
        vmid: 'og:description',
        property: 'og:description',
        content: this.description,
        },
        {
        vmid: 'description',
        property: 'description',
        content: this.description,
        },
        {
        vmid: 'og:image',
        property: 'og:image',
        content: this.featured_image,
        },
        {
        vmid: 'og:title',
        property: 'og:title',
        content: this.title,
        },
      ]
    }
  },
  components: {
    BlatProjects, BlatGallery, BlatVScroll, BlatVideo
  },
  props: {
    slug: String,
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    title() {
      if( this.project ) {
        return `${this.project.title.rendered} - ${this.project.subtitle}`;
      }
      return undefined;
    },
    description() {
      if( this.project ) {
        return this.project.seo.description;
      }
      return undefined;
    },
    featured_image() {
      return this.project ? this.project.featured_image.original : undefined;
    },
    project() {
      return this.$store.getters['projects/slug'](this.slug) || null;
    },
    allCategories() {
      return this.$store.getters['categories/all'] || [];
    },
    categories() {
      if( this.project && this.allCategories.length ) {
        let categories = this.allCategories.filter( ({cat_ID}) => {
          return this.project.project_category.includes(cat_ID);
        });
        return categories.map( ({cat_ID}) => cat_ID );
      }
      return [];
    },
    relatedProjects() {
      if( this.categories.length ) {
        let allProjects = this.$store.getters['projects/all'];
        let videoTag = this.$store.getters['tags/slug']('video');
        let getVideos = this.project.project_tag.includes( videoTag.cat_ID );
        let sameTypeProjects = allProjects.filter( ({project_tag}) =>  getVideos == project_tag.includes(videoTag.cat_ID) );

        // Get Same Type and Same Category
        let projects = sameTypeProjects.filter( ({id, project_category}) => {
          if( id != this.project.id ) {
            for(let i = 0; i < project_category.length; i++ ) {
              if( this.categories.includes( project_category[i] )) return true;
            }
          }
          return false;
        });
        projects = projects.sort( () => Math.random() - .5 );

        // Get Same Type and Different Category
        if( projects.length < 3 ) {
          let selectedIds = projects.map( ({id}) => id);
          let otherCatProjects = sameTypeProjects.filter( ({id}) => {
            if( id != this.project.id ) {
              return !selectedIds.includes(id);
            }
            return false;
          });
          otherCatProjects = otherCatProjects.sort( () => Math.random() - .5 );
          projects = [...projects, ...otherCatProjects];
        }

        // Get Different Type and Same Category
        if( projects.length < 3 ) {
          let otherTypeProjects = allProjects.filter( ({project_tag}) =>  getVideos != project_tag.includes(videoTag.cat_ID) );
          otherTypeProjects = otherTypeProjects.filter( ({id, project_category}) => {
            if( id != this.project.id ) {
              for(let i = 0; i < project_category.length; i++ ) {
                if( this.categories.includes( project_category[i] )) return true;
              }
            }
            return false;
          });
          otherTypeProjects = otherTypeProjects.sort( () => Math.random() - .5 );
          projects = [...projects, ...otherTypeProjects];
        }
        return projects;
      }
      return [];
    },
  },
  methods: {
  },
  watch: {
  },
  mounted() {
  },
}
</script>
