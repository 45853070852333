import VueRouter from 'vue-router'
import Home from './components/templates/Home.vue'
import Project from './components/templates/Project.vue'
import Projects from './components/templates/Projects.vue'
import Publication from './components/templates/Publication.vue'
import Publications from './components/templates/Publications.vue'
import Page from './components/templates/Page.vue'

const routes = [
  { path: '/:lang(pt)?', component: Home, name: 'home' },
  { path: '/:lang(pt)?/published-work/',
    component: Publications,
    name: 'publications',
    props: (route) => ({slug: route.query.c}),
  },
  { path: '/:lang(pt)?/portfolio/projects/',
    component: Projects,
    name: 'projects',
    props: (route) => ({slug: route.query.c, ignore_tag: 'video'}),
  },
  { path: '/:lang(pt)?/portfolio/video/',
    component: Projects,
    name: 'videos',
    props: (route) => ({slug: route.query.c, tag: 'video'}),
  },
  { path: '/:lang(pt)?/published-work/:slug', component: Publication, name: 'publication', props: true },
  { path: '/:lang(pt)?/portfolio/projects/:slug', component: Project, name: 'project', props: true },
  { path: '/:lang(pt)?/portfolio/video/:slug', component: Project, name: 'video', props: true },
  { path: '/:lang(pt)?/portfolio/:category/:slug', component: Project, name: 'Project', props: true },
  {
    path: "/:lang(pt)?/:pageSlug",
    name: "page",
    component: Page,
    props: true,
  },
];

import Scroll from 'animated-scroll-to';
export function createRouter ({server = false}) {
  let router = new VueRouter({
    mode: 'history',
    routes 
  });
  if( !server ) {
    router.beforeEach((to, from, next) => {
      setTimeout( () => {
        Scroll(document.body, {maxDuration: 0});
      }, 300);
      next();
    })
  }
  return router;
}
