import api from './index.js'

export default {
    get(cb, params = {page: 1, per_page: 100}) {
        api.get('blat/v1/languages', params)
        .then( (res) => {
            cb(res.data);
            let totalPages = res.headers['x-wp-totalpages'] || 1;
            if( totalPages > params.page ) {
                this.get(cb, {...params, page: params.page + 1});
            }
        });
    },
}