<template>
  <section class="banner" :style="bgImg">
    <div class="slide one" v-show="currentPlayerId == 1 && playing" ref="slideone" :style="bgImg"></div>
    <div class="slide" v-show="currentPlayerId == 2" ref="slidetwo"></div>
    <a href="#" @click.prevent="$emit('go')" class="scroll"></a> 
    <div class="rotate" v-if="isTouchCapable && portrait">
      <svg>
        <use href="../assets/menu.svg#rotate"></use>
      </svg>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    videos: {required: true},
    thumbnails: {required: true},
  },
  data() {
    return {
      menu: false,
      active: 0,
      playing: false,
      players: [],
      isTouchCapable: false,
      portrait: false,
    }
  },
  computed: {
    currentPlayer() {
      return this.players.find( ({id}) => this.currentPlayerId === id);
    },
    currentPlayerId() {
      return (this.active%this.players.length) + 1;
    },
    nextPlayer() {
      return this.players.find( ({id}) => this.currentPlayerId !== id);
    },
    bgImg() {
      if( this.thumbnails ) {
        return {
          'background-image': `url(${this.thumbnails[this.active%this.thumbnails.length]}) !important`,
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    addPlayer(el) {
      let options = {
        id: this.videos[this.active],
        width: 1920,
        controls: false,
        autoplay: false,
        muted: false,
        background: true,
        quality: '1080p',
      };
      let player = {
        id: this.players.length + 1,
        controls: new this.$player(el, options),
        el: el,
        video: this.active,
      };
      player.controls.setAutopause(false);

      // let debug = ['play', 'pause', 'ended'];
      let debug = [];
      for (let index = 0; index < debug.length; index++) {
        let eventName = debug[index];
        player.controls.on(eventName, (event) => {
          console.log(`${player.id}: ${eventName}`);
          console.log(event);
        });
      }

      player.controls.on('ended', () => {
        this.nextPlayer.controls.play();
        this.active++;
        this.loadNextVideo();
      });

      this.players.push(player);
    },
    checkPortrait() {
      this.portrait = screen.width < screen.height || Math.abs(window.orientation) === 90;
      return this.portrait;
    },
    loadNextVideo() {
      let index = (this.active+1)%this.videos.length;
      let video = this.videos[index];
      this.nextPlayer.controls.loadVideo(video)
      .then( () => {
        // this.nextPlayer.controls.setVolume(1);
        this.nextPlayer.video = index;
        this.nextPlayer.controls.setAutopause(false)
        .then(
          this.nextPlayer.controls.play() // Start buffering
          .then( () => {
            this.nextPlayer.controls.pause();
          })
        );
      });
    },
    play() {
      this.currentPlayer.controls.play()
      .then(() => {
        this.addPlayer(this.$refs.slidetwo);
        this.loadNextVideo();
        this.playing = true;
        setTimeout(() => this.portrait = false, 4000);
        document.body.removeEventListener('mouseover', this.play);
      });
    },
  },
  mounted() {
    if( !this.$server ) {
      this.addPlayer(this.$refs.slideone);
      this.play();
      document.body.addEventListener('mouseover', this.play);
      this.isTouchCapable = 'ontouchstart' in window ||
        window.DocumentTouch && document instanceof window.DocumentTouch ||
        navigator.maxTouchPoints > 0 ||
        window.navigator.msMaxTouchPoints > 0;
      this.checkPortrait();
    }
  },
  destroyed() {
    document.body.removeEventListener('mouseover', this.play);
  },
}
</script>

<style scoped>
.banner {
  /* background-image: url('../assets/tail-spin.svg'); */
  /* background-size: 100px 100px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.slide {
  background-size: cover;
  height: 100%;
}
</style>
