<template>
  <section id="header" :class="{open: open}" class="header">
    <router-link :to="{name: 'home', params: {lang: $root.lang}}" class="logo" @click="open = false">
      <img alt="Ricardo Oliveira Alves Logo" src="../assets/logo.svg">
    </router-link> 
    <nav class="menu" :class="{open: open}" @click="close">
      <transition name="menu">
        <ul v-show="open" class="main" ref="menu">
          <li>
            <router-link :to="{name: 'projects', params: {lang: $root.lang}}">
              <span v-text="$t('projects')"></span>
            </router-link>
            <ul class="sub" v-if="categories.length" :class="{open: $route.name == 'projects' || $route.name == 'project' || $route.name == 'Project'}">
              <li class="all">
                <router-link :to="{name: 'projects', params: {lang: $root.lang}}">
                  <a href="" @click="open = false">
                    <span v-text="$t('all')"></span>
                  </a>
                </router-link>
              </li>
              <li v-for="category in categories" :key="category.id">
                <router-link :class="{'router-link-exact-active': category.slug == openCategory}" :to="{name: 'projects', query: {c: category.slug}, params: {lang: $root.lang}}" @click="open = false" v-text="language == 'en' ? category.name : category.name_t[language]">
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link :to="{name: 'videos', params: {lang: $root.lang}}">
              Video
            </router-link>
            <ul class="sub" v-if="categories.length" :class="{open: $route.name == 'videos' || $route.name == 'video'}">
              <li class="all">
                <router-link :to="{name: 'videos', params: {lang: $root.lang}}">
                  <a href="" @click="open = false">
                    All
                  </a>
                </router-link>
              </li>
              <li v-for="category in categories" :key="category.id">
                <router-link :class="{'router-link-exact-active': category.slug == openCategory}" :to="{name: 'videos', query: {c: category.slug}, params: {lang: $root.lang}}" @click="open = false" v-text="language == 'en' ? category.name : category.name_t[language]">
                </router-link>
              </li>
            </ul>
          </li>
          <li v-for="item in items" :key="item.ID">
            <router-link :to="{name: item.object == 'page' ? 'page' : item.url.replace('#', ''), params: {pageSlug: item.object_slug, lang: $root.lang}}" v-text="item.title">
            </router-link>
          </li>
        </ul>
      </transition>
    </nav>
    <nav class="header_language">
      <a href="#" @click.prevent="setLanguage('en')" :class="{active: $route.params.lang == null}">EN</a>
      <a href="#" @click.prevent="setLanguage('pt')" :class="{active: $route.params.lang }">PT</a>
    </nav>
    <a href="#" @click.prevent="open = !open" :class="{toggle: true, open: open}">
      <svg>
        <use href="../assets/menu.svg#menu"></use>
      </svg>
    </a>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Header',
  props: {
  },
  serverPrefetch() {
    return new Promise( (resolve , reject) => {
      let fetched = 0;
      let done = () => {
        if( fetched > 4 ) {
          console.log('fetched more than 4');
          resolve();
        }
      }
      this.fetchLanguages().then( () => {
        console.log('got languages');
        this.fetchCategories().then( () => {
          console.log('got categories');
          fetched++;
          done();
        }).catch(() => reject());
        this.fetchItems().then( ({data}) => {
          console.log('got items');
          this.items = data;
          fetched++;
          done();
        }).catch(() => reject());
        this.fetchPages().then( () => {
          console.log('got pages');
          fetched++;
          done();
        }).catch(() => reject());
        this.fetchTags().then( () => {
          console.log('got tags');
          fetched++;
          done();
        }).catch(() => reject());
        this.fetchProjects().then( () => {
          console.log('got projects');
          fetched++;
          done();
        }).catch(() => reject());
      }).catch(() => reject());
    })
  },
  data() {
    return {
      open: false,
      projectsOpen: false,
      noCloseRoutes: ['videos', 'projects'],
      projectRoutes: ['video', 'project', 'Project'],
      items: [],
      openCategory: null,
    }
  },
  computed: {
    ...mapState('categories', {
      categories: 'all',
    }),
    ...mapState('languages', {
      languages: 'all',
      language: 'language',
    }),
    ...mapState('pages', {
      pages: 'all',
    }),
    ...mapState('projects', {
      projects: 'all',
    }),
    ...mapState('tags', {
      tags: 'all',
    }),
    selectedProject() {
      if(this.projectRoutes.includes(this.$route.name)) {
        return this.$store.getters['projects/slug'](this.$route.params.slug);
      }
      return null;
    },
  },
  methods: {
    mobile() {
      if(this.$server) {
        return false;
      }
      return getComputedStyle(document.documentElement).getPropertyValue('--hover') == 0;
    },
    startHeader() {
      if( this.pages.length == 0 ) {
        this.fetchPages();
      }
      if( this.projects.length == 0 ) {
        this.fetchProjects();
      }
      if( this.tags.length == 0 ) {
        this.fetchTags();
      }
      if( this.categories.length == 0 ) {
        this.fetchCategories();
      }
      if( this.languages.length == 0 ) {
        this.fetchLanguages();
      }
    },
    reset() {
      this.items = [];
      this.$store.commit('pages/setPages', []);
      this.$store.commit('projects/setProjects', []);
    },
    setLanguage(lang) {
      if( lang != this.$root.locale ) {
        this.$store.commit('languages/setLanguage', lang);
        this.reset();
        this.startHeader();
      }
    },
    path(url){
      let path = url.replace("http://clientes.ricardooliveiraalves.com", "");
      path = path.replace("https://clientes.ricardooliveiraalves.com", "");
      return path;
    },
    close($event) {
      if(this.mobile()) {
        let tag = $event.target.tagName;
        if( tag == "UL" || tag == "LI" ) {
          this.open = false;
        }
      }
    },
    fetchCategories() {
      return this.$store.dispatch('categories/getAll');
    },
    fetchPages() {
      return this.$store.dispatch('pages/getAll');
    },
    fetchProjects() {
      return this.$store.dispatch('projects/getAll');
    },
    fetchTags() {
      return this.$store.dispatch('tags/getAll');
    },
    fetchItems() {
      let promise = this.get('blat/v1/menu', {lang: this.language});
      promise.then( ({data}) => {
        this.items = data;
      });
      return promise;
    },
    fetchLanguages() {
      return this.$store.dispatch('languages/getAll');
    },
    setOpenCategory() {
      if( this.$route.query.c ) {
        this.openCategory = this.$route.query.c;
      } else if( this.selectedProject != null && this.openCategory == null ) {
        let category = this.$store.getters['categories/id'](this.selectedProject.project_category[0]);
        if( category ) {
          this.openCategory = category.slug;
        }
      } else if( this.selectedProject == null ) {
        this.openCategory = null;
      }
    },
    openSubMenu() {
      if( this.$server ) return;
      this.$nextTick()
      .then( () => {
        let closed = this.$refs.menu.querySelectorAll('ul.sub:not(.open)');
        let sub = this.$refs.menu.querySelector('ul.sub.open');
        console.log('opening sub menu');
        console.log(sub);
        if(this.mobile()) {
          closed.forEach(element => {
            element.style.maxHeight = null;
          });
          if( sub ) {
            sub.style.maxHeight = `${sub.scrollHeight}px`;
          }
        } else {
          if( closed ) {
            closed.forEach(element => {
              element.style.maxWidth = null;
            });
          }
          if( sub ) {
            sub.style.maxWidth = `${sub.scrollWidth}px`;
          }
        }
      })
    }
  },
  watch: {
    selectedProject() {
      this.setOpenCategory();
    },
    categories() {
      this.openSubMenu();
      this.setOpenCategory();
    },
    language: {
      immediate: true,
      handler() {
        this.fetchItems();
      }
    },
    $route: {
      handler() {
        this.setOpenCategory();
        if(this.mobile()) {
          if( this.noCloseRoutes.includes(this.$route.name) ) {
            if( Object.keys(this.$route.query).length ) {
              this.open = false;
            }
          } else {
            this.open = false;
          }
        } else if ( this.$route.name != 'home' ) {
          this.open = true;
        }
        this.openSubMenu();
      },
      immediate: true,
    },
    open() {
      let body = document.body;
      if( this.open )  {
        body.classList.add("menu-open");
      } else {
        body.classList.remove("menu-open");
      }
      this.openSubMenu();
    }
  },
  created() {
    console.log("Created Header:");
    console.log(this.language);
  },
  mounted() {
    this.startHeader();
  }
}
</script>