<template>
  <section id="page" v-if="loaded">
    <!-- <BlatBanner v-if="videos.length > 2" :videos="videos" :thumbnails="thumbnails" @go="$scroll($refs.content)"></BlatBanner> -->
    <div class="content" v-html="page.content.rendered"></div>
  </section>
</template>

<script>
// import BlatBanner from '../Banner.vue'

export default {
  name: 'Page',
  components: {
    // BlatBanner,
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
        vmid: 'og:description',
        property: 'og:description',
        content: this.description,
        },
        {
        vmid: 'description',
        property: 'description',
        content: this.description,
        },
        {
        vmid: 'og:image',
        property: 'og:image',
        content: this.featured_image,
        },
      ]
    }
  },
  props: {
    pageSlug: {required: true},
  },
  data() {
    return {
      open: false,
      loaded: false,
    }
  },
  watch: {
    page() {
      this.loaded = true;
    },
  },
  computed: {
    title() {
      return this.page ? this.page.title.rendered : undefined;
    },
    page() {
      return this.$store.getters['pages/slug'](this.pageSlug);
    },
    description() {
      if( this.page ) {
        return this.page.seo.description;
      }
      return undefined;
    },
    featured_image() {
      return this.page ? this.page.featured_image.original : undefined;
    },
    randomIndexes() {
      if( this.page ) {
        let indexes = [...Array(this.page.thumbnails.length).keys()];
        let nr = indexes.length;
        let random = [];
        for (let i = 0; i < nr; i++) {
          let newIndex = Math.floor(Math.random() * (indexes.length));
          random.push(indexes[newIndex]);
          indexes.splice(newIndex,1);
        }
        return random;
      }
      return [];
    },
    thumbnails() {
      if( this.randomIndexes.length ) {
        let videos = [];
        for (let i = 0; i < this.randomIndexes.length; i++) {
          videos.push(this.page.thumbnails[this.randomIndexes[i]]);
        }
        return videos;
      }
      return [];
    },
    videos() {
      if( this.randomIndexes.length ) {
        let videos = [];
        let pageVideos = this.page.videos;
        for (let i = 0; i < this.randomIndexes.length; i++) {
          videos.push(pageVideos[this.randomIndexes[i]]);
        }
        return videos;
      }
      return [];
    },
  },
  mounted() {
    if( this.page ) {
      this.loaded = true;
    }
  },
}
</script>

<style scoped>
#header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
}
</style>
